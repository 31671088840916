import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import AddForm from '../../components/common/AddForm';

import {
  initialValues,
  inputFields,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
} from '../../shared/enums/candidates_enum';
import {
  useCreateCandidate,
  useSingleCandidate,
  useGetDropdownOptions,
  useSelectAllCandidate,
} from '../../shared/hooks/UseCandidate';

import { convertToFormData, upload } from '../../shared/upload';

const AddCandidate = ({ match }) => {
  let history = useHistory();
  const [addData] = useCreateCandidate();
  const [featuredImage, setFeaturedImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  console.log('featuredImage', featuredImage);

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values, featuredImage });
    await addData(data);
    history.goBack();
  };

  const [
    dropdownOptions,
    setEmployeeSearchField,
    setEmployeeSearchValue,
    setClientSearchField,
    setClientSearchValue,
  ] = useGetDropdownOptions();

  const loadOptions = async (inputValue, callback, field) => {
    if (field == 'recruiter') {
      setEmployeeSearchField('name');
      setEmployeeSearchValue(inputValue);
      callback(dropdownOptions.recruiter);
    }
    if (field == 'client_name') {
      setClientSearchField('name');
      setClientSearchValue(inputValue);
      callback(dropdownOptions.client_name);
    }
  };

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={`Add ${PAGE_SINGLE_TITLE}`}
          mainLinkTitle={PAGE_TITLE}
          mainLinkUrl={LINK_URL}
          activeLink='Add'
        />
      </div>
      <div className='container-fluid'>
        <div className='col-lg-9'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='card-title'> {PAGE_SINGLE_TITLE} </h4>
              <p className='card-title-desc'>
                Enter Details to add {PAGE_SINGLE_TITLE}
              </p>
            </div>

            <AddForm
              edit={false}
              featuredImage={featuredImage}
              gallery={gallery}
              setFeaturedImage={setFeaturedImage}
              setGallery={setGallery}
              submitForm={submitFormClicked}
              inputFields={inputFields}
              initialValues={initialValues}
              dropdown_options={dropdownOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCandidate;
