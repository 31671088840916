import api from '../../domain/api';
import {
  GET_CANDIDATES,
  ADD_CANDIDATE,
  EDIT_CANDIDATE,
  GET_CANDIDATE,
  RESET_CANDIDATE,
  GET_ALL_CANDIDATES,
  ERROR_CANDIDATE,
  INTERVIEWS_BY_CANDIDATE,
  CANDIDATE_STATS_STARTED,
  CANDIDATE_STATS,
  CANDIDATE_STATS_ENDED,
} from '../types/candidate_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';
export const addCandidate = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/candidates`, formData);
    dispatch({
      type: ADD_CANDIDATE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const cvImport = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/cvs/cv-import`, formData);
    dispatch({
      type: ADD_CANDIDATE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCandidates =
  ({ pageNumber = '', params }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_CANDIDATE,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));

      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      let URL_TO_FETCH = '';
      if (params) {
        let search_params = '';
        params.map((item) => {
          search_params += `&exact[${item.label}]=${item.value}`;
        });
        URL_TO_FETCH = `/candidates?&${search_params}&${query}`;
      } else {
        URL_TO_FETCH = `/candidates?&${query}`;
      }

      const { data } = await api.get(URL_TO_FETCH);

      dispatch({
        type: GET_CANDIDATES,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getCandidatesStats =
  ({ params }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CANDIDATE_STATS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));

      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      let URL_TO_FETCH = '';
      if (params) {
        let search_params = '';
        params.map((item) => {
          search_params += `&exact[${item.label}]=${item.value}`;
        });
        URL_TO_FETCH = `/candidates/stats/?${search_params}&${query}`;
      } else {
        URL_TO_FETCH = `/candidates/stats/?${query}`;
      }

      const { data } = await api.get(URL_TO_FETCH);

      dispatch({
        type: CANDIDATE_STATS,
        payload: data,
      });
      dispatch({
        type: CANDIDATE_STATS_ENDED,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getInterviewsByCandidate = (id) => async (dispatch) => {
  try {
    const { data } = await api.get(`/interviews?select[candidate_name]=${id}`);

    dispatch({
      type: INTERVIEWS_BY_CANDIDATE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCandidate = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_CANDIDATE,
    });
    const { data } = await api.get(`/candidates/${id}`);

    dispatch({
      type: GET_CANDIDATE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCandidate = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/candidates/${id}`, formData);
    dispatch({
      type: EDIT_CANDIDATE,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCandidate = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/candidates/${id}`);
    dispatch(setAlert('Candidate Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCandidates =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(
        `/candidates/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_CANDIDATES,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const exportCandidates = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/candidates/export-report`);

    dispatch({
      type: GET_ALL_CANDIDATES,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_CANDIDATE });
};
