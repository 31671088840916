import api from '../../domain/api';
import {
  GET_JOBPOSTS,
  ADD_JOBPOST,
  EDIT_JOBPOST,
  GET_JOBPOST,
  RESET_JOBPOST,
  GET_ALL_JOBPOSTS,
  ERROR_JOBPOST,
  INTERVIEWS_BY_JOBPOST,
  INTERVIEWS_STATS_BY_JOBPOST,
  CANDIDATES_BY_JOBPOST,
  JOBPOST_STATS_STARTED,
  JOBPOST_STATS,
  JOBPOST_STATS_ENDED,
} from '../types/jobpost_type';
import * as qs from 'qs';

import { handleError } from '../../shared/handleError';
import { setAlert } from './alert';
export const addJobpost = (formData) => async (dispatch, getState) => {
  try {
    const { data } = await api.post(`/jobposts`, formData);
    dispatch({
      type: ADD_JOBPOST,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getJobposts =
  ({ pageNumber = '', params }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_JOBPOST,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      let URL_TO_FETCH = '';
      if (params) {
        let search_params = '';
        params.map((item) => {
          search_params += `&exact[${item.label}]=${item.value}`;
        });
        URL_TO_FETCH = `/jobposts?&${search_params}&${query}`;
      } else {
        URL_TO_FETCH = `/jobposts?&${query}`;
      }

      const { data } = await api.get(`${URL_TO_FETCH}`);

      dispatch({
        type: GET_JOBPOSTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const getJobpostsStats =
  ({ params }) =>
  async (dispatch) => {
    console.log('PARAMS', params);
    try {
      dispatch({
        type: JOBPOST_STATS_STARTED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));

      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });
      let URL_TO_FETCH = '';
      if (params) {
        let search_params = '';
        params.map((item) => {
          search_params += `&exact[${item.label}]=${item.value}`;
        });
        URL_TO_FETCH = `/jobposts/stats/?${search_params}&${query}`;
      } else {
        URL_TO_FETCH = `/jobposts/stats/?${query}`;
      }

      const { data } = await api.get(URL_TO_FETCH);

      dispatch({
        type: JOBPOST_STATS,
        payload: data,
      });
      dispatch({
        type: JOBPOST_STATS_ENDED,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const getInterviewsByJobpost = (id) => async (dispatch) => {
  try {
    // TODO: fix this
    const { data } = await api.get(`/interviews/job-posts/${id}`);

    dispatch({
      type: INTERVIEWS_BY_JOBPOST,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCandidatesByJobpost = (id) => async (dispatch) => {
  try {
    // TODO: fix this
    const { data } = await api.get(`/candidates/job-posts/${id}`);

    dispatch({
      type: CANDIDATES_BY_JOBPOST,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getInterviewsByJobpostStats = (id) => async (dispatch) => {
  try {
    // TODO: fix this
    const { data } = await api.get(`/interviews/job-posts/${id}/stats`);

    dispatch({
      type: INTERVIEWS_STATS_BY_JOBPOST,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const getJobpost = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_JOBPOST,
    });
    const { data } = await api.get(`/jobposts/${id}`);

    dispatch({
      type: GET_JOBPOST,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editJobpost = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.put(`/jobposts/${id}`, formData);
    dispatch({
      type: EDIT_JOBPOST,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteJobpost = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/jobposts/${id}`);
    dispatch(setAlert('Jobpost Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllJobposts =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      const { data } = await api.get(
        `/jobposts/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_JOBPOSTS,
        payload: data,
      });
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const exportAllJobposts = () => async (dispatch) => {
  try {
    const { data } = await api.get(`/jobposts/export-report`);

    dispatch({
      type: GET_ALL_JOBPOSTS,
      payload: data,
    });
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const handleErrorLocal = () => async (dispatch) => {
  dispatch({ type: ERROR_JOBPOST });
};
