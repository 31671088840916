import React, { useEffect, useState } from 'react';
import api from '../../domain/api';
import { URI } from '../../domain/constant';
import moment from 'moment';
import { convertToFormData } from '../../shared/upload';
import Header from '../../components/template/Header';
import BreadCrumb from '../../components/template/BreadCrumb';

export default function AllBills({ client }) {
  const [billData, setBillData] = useState(null);
  const [bill_date, setBill_date] = useState(moment().format('YYYY-MM-DD'));
  const [billDateError, setBillDateError] = useState(null);

  const [bill_attachment, setBill_attachment] = useState(null);
  const [billAttachmentError, setBillAttachmentError] = useState(null);

  const getBillData = async () => {
    const response = await api.get(`/clients/create-bill`);
    setBillData(response.data);
  };

  useEffect(() => {
    getBillData();
  }, []);
  console.log('bill_attachment', bill_attachment);

  const handleSaveBill = async () => {
    if (!bill_date) {
      setBillDateError('Required');
      return;
    }
    if (!bill_attachment) {
      setBillAttachmentError('Required');
      return;
    }
    const data = convertToFormData({
      values: { client, bill_date },
      featuredImage: { bill_attachment },
    });

    const newResponse = await api.post('/clients/create-bill', data);
    await getBillData();
  };

  const deleteBillHandler = async (id) => {
    const deleteBill = await api.delete(`/clients/bill/${id}`);
    await getBillData();
  };

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={`All Bills`}
          mainLinkTitle={'All Bills'}
          mainLinkUrl={'All Bills'}
          activeLink='View'
        />
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'> Bills </div>
                <div className='card-body'>
                  <div>
                    <table className='table table-bordered table-stripped'>
                      <thead>
                        <tr>
                          <th> Bill Date </th>
                          <th> Client </th>
                          <th> Created By </th>
                          <th> Attachment </th>
                        </tr>
                      </thead>
                      <tbody>
                        {billData &&
                          billData?.map((bill) => {
                            return (
                              <tr key={bill?._id}>
                                <td> {bill.bill_date} </td>
                                <td> {bill?.client?.name} </td>
                                <td> {bill?.created_by?.name} </td>
                                <td>
                                  <a
                                    href={`${URI}/${bill?.bill_attachment}`}
                                    target='_blank'
                                  >
                                    View Bill
                                  </a>
                                  <button
                                    className='btn btn-sm btn-danger ml-2'
                                    onClick={() => deleteBillHandler(bill?._id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        {billData && billData?.length === 0 && (
                          <tr>
                            <td colSpan={4}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
