export const MENU = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN', 'BD'],
  },
  {
    label: 'Candidates',
    link: '/candidates',
    role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN'],
  },
  {
    label: 'Clients',
    link: '/clients',
    role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN', 'BD'],
  },
  {
    label: 'Requirements',
    link: '/jobPosts',
    role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN'],
  },

  {
    label: 'Recruiters',
    link: '/employees',
    role: ['MANAGER', 'SUPER ADMIN'],
  },

  {
    label: 'Contacts',
    link: '/contacts',
    role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN'],
  },
  {
    label: 'Interviews',
    link: '/interviews',
    role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN'],
  },
  {
    label: 'Attendances',
    link: '#',
    role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN', 'BD'],
    menu: [
      // {
      //   label: "Attendances",
      //   link: "/attendances",
      //   role: ["RECRUITER", "MANAGER", "SUPER ADMIN"],
      // },

      {
        label: 'Attendance by Date',
        link: '/attendances-by-date',
        role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN'],
      },
      {
        label: 'Attendance by Employee',
        link: '/attendances-by-employee',
        role: ['MANAGER', 'SUPER ADMIN'],
      },
      {
        label: 'Mark Attendance',
        link: '/attendances/add',
        role: ['RECRUITER', 'MANAGER', 'SUPER ADMIN'],
      },
    ],
  },
  {
    label: 'Settings',
    link: '#',
    role: ['SUPER ADMIN', 'MANAGER'],
    menu: [
      {
        label: 'Candidate Status',
        link: '/candidatestatus',
        role: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Skill Sets',
        link: '/skillsets',
        role: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Sources',
        link: '/sources',
        role: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Industries',
        link: '/industrys',
        role: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'ATS Users',
        link: '/admins',
        role: ['SUPER ADMIN'],
      },
      {
        label: 'Holidays',
        link: '/holidays',
        role: ['SUPER ADMIN'],
      },
    ],
  },
  {
    label: 'Reports',
    link: '#',
    role: ['SUPER ADMIN', 'MANAGER'],
    menu: [
      {
        label: 'Bills',
        link: '/reports/bills',
        role: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Closed By Recruiter',
        link: '/reports/closed-by-recruiter',
        role: ['SUPER ADMIN', 'MANAGER'],
      },

      {
        label: 'Entry By Recruiter',
        link: '/reports/entry-by-recruiter',
        role: ['SUPER ADMIN', 'MANAGER'],
      },
      {
        label: 'Requirements Report',
        link: '/reports/job-post-candidates',
        role: ['SUPER ADMIN', 'MANAGER'],
      },
    ],
  },
];
